import React from 'react';

import "./Footer.scss";
import imgLogo from "assets/logo.png";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";

const Footer:React.FC = () => {
  return (
    <footer className="footer pt-2 pt-md-3 pt-lg-5 bg-secondary">
        <div className="container-lg d-flex flex-column align-items-center justify-content-center text-white">
            <img className="logo img-fluid mb-2 mb-md-4" alt="Brand Name" src={imgLogo} />
            <div className="m-0 p-0">
            <Navbar collapseOnSelect expand="xl" variant="dark">
              <Container>
                <Navbar.Toggle
                  className="navbar-toggler"
                  // type="button"
                  // data-toggle="collapse"
                  // data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  // aria-expanded="false"
                  // aria-label="Toggle navigation"
                />
                <Navbar.Collapse
                  className="collapse navbar-collapse"
                  id="navbarNavDropdown"
                >
                  <Nav className="navbar-nav navbar-dark me-auto mb-2 mb-lg-0">
                    <Nav.Link className="nav-item" href="#unset">
                      <span className="nav-link fs-7">Felhasználási feltételek</span>
                    </Nav.Link>
                    <Nav.Link className="nav-item" href="#unset">
                      <span className="nav-link fs-7">Cookie irányelvek</span>
                    </Nav.Link>
                    <Nav.Link className="nav-item" href="#unset">
                      <span className="nav-link fs-7">Adatvédelmi nyilatkozat</span>
                    </Nav.Link>
                    <Nav.Link className="nav-item" href="#unset">
                      <span className="nav-link fs-7">Kapcsolat</span>
                    </Nav.Link>
                    <Nav.Link className="nav-item" href="#unset">
                      <span className="nav-link fs-7">Játékszabályzat</span>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            </div>
            <p className="legal m-2 m-md-3 m-lg-4 fs-8">&copy; 2022 ... - Minden jog fenntartva!</p>
        </div>
    </footer>
  );
}

export default Footer;
