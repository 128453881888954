import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { eachDayOfInterval, format, min, parseISO } from "date-fns";
import { hu } from "date-fns/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "./CustomInput";
import { uploadAPCodeSchema } from "utils/validation-schemas";
import { RegistrationHandlerProps } from "./Form";
import { uploadHandlerAp } from "utils/API-schemas";
import { PeriodContext } from "context/period-context";

interface UploadApFormProps {
  onChangeRegistrationHandler(options: RegistrationHandlerProps): void;
  onOpenModal(): void;
  dateUI: "select" | "datepicker";
}

const UploadApForm: React.FC<UploadApFormProps> = ({
  onChangeRegistrationHandler,
  onOpenModal,
  dateUI,
}) => {

  const promotionPeriod = useContext(PeriodContext)?.find(p => p.type === 'promotion');

  // generates date formats between start and end date
  const datesIntervalArray =
    promotionPeriod
    ?
    eachDayOfInterval({
      start: parseISO(promotionPeriod.start_time),
      end: min([ new Date(), parseISO(promotionPeriod.end_time) ]),
    })
    :
    []
  ;

  return (
    <Formik
      validateOnChange={false} //disable the validation at every change
      validateOnBlur={false} // disable the validation at every blur
      initialValues={{
        email: "",
        code: "",
        day: format(new Date(), "yyyy-MM-dd"), // SELECT: new Date().toISOString(), DATEPICKER: new Date()
        hour: "",
        min: "",
      }}
      //with Yup we can create a schema, that Formik understands
      //to each condition we can attach an error message, that the ErrorMessage component will receive automatically
      validationSchema={uploadAPCodeSchema}
      onSubmit={async (values, { setErrors, setSubmitting, setStatus }) => {
        const succesOperations = () => {
          values.code = "";
          setStatus({ success: "Sikeres kódfeltöltés!" });
        };
        setStatus();
        const { email, code, day, hour, min } = values;

        // SELECT: parseISO(values.day), DATEPICKER: values.day
        const purchase_time = `${day} ${hour.toString().padStart(2, "0")}:${min
          .toString()
          .padStart(2, "0")}`;

        const config = {
          email,
          code,
          purchase_time,
        };

        const upload = async () => {
          try {
            await uploadHandlerAp({
              ...config,
            });
            setSubmitting(false);
            succesOperations();
          } catch (error: any) {
            const errorMessage = error.response.data.errors[0].code;
            if (errorMessage === "email:not_found") {
              onChangeRegistrationHandler({
                email,
                onSuccesfulRegister: upload,
              });
              onOpenModal();
            }
            if (errorMessage === "code:already_uploaded") {
              setErrors({ code: "A kódot már korábban feltöltötték!" });
            }
            if (errorMessage === "code:invalid") {
              setErrors({ code: "Érvénytelen kód!" });
            }
          }
        };
        upload();
      }}
    >
      {({ setFieldValue, values, errors, isSubmitting, status }) => {
        const errorMessages = Object.values(errors);

        return (
          <div className="form container-lg p-1 mx-sm-auto my-sm-2 p-sm-2 p-lg-5 d-flex flex-column align-items-center justify-content-center">
            <h2 className="fw-bold">Kódfeltöltés</h2>
            <p>Töltsd fel a blokkon található adatokat!</p>
            {status?.success && errorMessages.length === 0 && (
              <p className="text-center bg-success p-2 p-md-3 rounded text-white fw-bold">
                {status.success}
              </p>
            )}
            <ul className="list-style-type-none empty-display-none text-center bg-danger p-2 p-md-3 rounded text-white fw-bold">
              {errorMessages.map((errorMsg, index) => (
                <li key={index}>{errorMsg}</li>
              ))}
            </ul>
            <Form>
              <CustomInput
                label="E-mail cím:"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="vezeteknev.keresztnev@valami.hu"
                className={`mt-2 mt-md-3 textinput validation-${
                  errors.email ? "error" : "success"
                }`}
                labelClassName="w-100"
                inputClassName="p-2 p-lg-3 rounded-pill w-100 fw-bold bg-white"
              />
              <CustomInput
                label="AP-kód:"
                name="code"
                type="text"
                placeholder="A124234JB5Y"
                className={`mt-2 mt-md-3 w-100 textinput validation-${
                  errors.code ? "error" : "success"
                }`}
                labelClassName="w-100 ap position-relative"
                inputClassName="pe-2 py-2 pe-lg-3 py-lg-3 rounded-pill w-100 fw-bold bg-white"
              />
              <div className="row">
                <label className="col-12 col-md-6 mt-2 mt-md-3">
                  <p>Vásárlás dátuma:</p>
                  {/* SELECT */}
                  {dateUI === "select" && (
                    <CustomInput
                      type="select"
                      name="day"
                      className="w-100"
                      labelClassName="w-100"
                      inputClassName="form-control w-100 fw-bold p-2 p-lg-3 rounded-pill styled-select bg-white text-secondary"
                    >
                      {datesIntervalArray.reverse().map((date) => {
                        return (
                          <option
                            key={date.getTime()}
                            value={format(date, "yyyy-MM-dd")}
                          >
                            {format(date, "MMMM d.", { locale: hu })}
                          </option>
                        );
                      })}
                    </CustomInput>
                  )}

                  {/* DATEPICKER */}
                  {dateUI === "datepicker" && (
                    <DatePicker
                      selected={parseISO(values.day)}
                      minDate={datesIntervalArray[0]}
                      maxDate={datesIntervalArray[datesIntervalArray.length - 1]}
                      className="form-control w-100 fw-bold text-secondary p-2 p-lg-3 rounded-pill "
                      name="day"
                      onChange={(date: Date) =>
                        setFieldValue("day", format(date, "yyyy-MM-dd"))
                      }
                      locale={hu}
                      dateFormat={"MMMM d."}
                      showDisabledMonthNavigation
                    />
                  )}
                </label>
                <div className="timepicker col-12 col-md-6">
                  <CustomInput
                    type="text"
                    inputMode="numeric"
                    name="hour"
                    label="Óra:"
                    placeholder="0-23"
                    maxLength={2}
                    className={`mt-2 mt-md-3 validation-${
                      errors.hour ? "error" : "success"
                    }`}
                    labelClassName="w-100"
                    inputClassName="p-2 p-lg-3 rounded-pill w-100 fw-bold"
                  />
                  <p className="pb-2 pb-lg-3 m-0 text-center text-muted fw-bold">
                    :
                  </p>
                  <CustomInput
                    type="text"
                    inputMode="numeric"
                    name="min"
                    label="Perc:"
                    maxLength={2}
                    placeholder="0-59"
                    className={`mt-2 mt-md-3 validation-${
                      errors.min ? "error" : "success"
                    }`}
                    labelClassName="w-100"
                    inputClassName="p-2 p-lg-3 rounded-pill w-100 fw-bold"
                  />
                </div>
              </div>
              <div>
                <p className="text-center fw-normal fs-7 m-1 m-md-3">
                  Kérünk, őrizd meg a feltöltött AP-kódot tartalmazó blokkot,
                  mert csak így tudjuk biztosítani nyereményed sieres átvételét.
                </p>
              </div>
              <div className="my-2 my-md-4 d-flex flex-column align-items-center justify-content-center">
                <button
                  className="button-secondary w-50 p-2 p-lg-3 rounded-pill border border-0 text-white text-uppercase fw-bold"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Feltöltöm a kódot
                </button>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default UploadApForm;
