import Modal from "react-bootstrap/Modal";

interface ModalOverlayProps {
  children: React.ReactNode;
  show: boolean;
}

const ModalOverlay: React.FC<ModalOverlayProps> = ({ children, show }) => {
  return (
    <Modal show={show} backdrop="static" size="xl">
      {children}
    </Modal>
  );
};

export default ModalOverlay;
