import React, { useContext } from "react";
import "./Hero.scss";
import imgHeadline from "assets/headline.png";
import { PeriodContext } from "context/period-context";
import { format } from "date-fns";
import { parseISO } from "date-fns";

const Hero: React.FC = () => {
  const promotionPeriod = useContext(PeriodContext)?.find(
    (date) => date.type === "promotion"
  );
  const formatPromotionDate = (date: string) =>
    format(parseISO(date), "yyyy.MM.dd");

  return (
    <section className="hero-section row my-5 mx-auto container-lg align-items-center">
      <div className="d-flex flex-column col-12 col-md-6 mb-3">
        <h1 className="mb-3 fw-bold">
          Headline helye kb. 50 karakter javasolt!
        </h1>
        <p className="mb-4 fw-semibold">
          SHL megfogalmazása, melynek ajánlott hossza nagyjából 120-150 karakter
          hosszúságra tehető.
        </p>
        <div className="button-container d-flex flex-row justify-content-center gap-2 flex-md-column align-items-stretch">
          <a
            href="#form-section"
            className="button-secondary p-2 px-md-5 py-md-3 rounded-pill w-100 text-uppercase text-white border border-0 fw-bold text-decoration-none d-flex align-items-center justify-content-center text-center"
          >
            Töltsd fel a kódot!
          </a>
          <a
            href="#unset"
            className="button-secondary p-2 px-md-5 py-md-3 rounded-pill w-100 text-uppercase text-white border border-0 fw-bold text-decoration-none d-flex align-items-center justify-content-center text-center"
          >
            CTA Copy
          </a>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <img
          alt="1200 x 760 Javasolt a háttérképhez illeszkedő PNG kép tervezése."
          src={imgHeadline}
        />
      </div>
      <div className="d-flex flex-column align-items-end col-12 p-3 p-lg-5">
        {promotionPeriod && (
          <p className="m-0 fs-7">
            A promóció időtartama:{" "}
            {formatPromotionDate(promotionPeriod.start_time)} -{" "}
            {formatPromotionDate(promotionPeriod.end_time)}
          </p>
        )}
        <p className="m-0 fs-7">A képek illusztrációk.</p>
      </div>
    </section>
  );
};

export default Hero;
