import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";
import Description from "./components/description/Description";
import Form from "./components/form/Form";
import Slogan from "./components/slogan/Slogan";
import Winners from "./components/winners/Winners";
import Footer from "./components/footer/Footer";

import "./styles/style.scss";
import { PeriodContextProvider } from "context/period-context";

function App() {
  return (
    <div className="App">
      <PeriodContextProvider>
        <Header />
        <Hero />
        <Description />
        <Form />
        <Slogan />
        <Winners />
        <Footer />
      </PeriodContextProvider>
    </div>
  );
}

export default App;
