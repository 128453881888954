import ModalOverlay from "components/modal/ModalOverlay";
import React, { useState } from "react";
import RegisterForm from "./RegisterForm";
import UploadApForm from "./UploadApForm";
import UploadBasicForm from "./UploadBasicForm";

import "./Form.scss";

export interface RegistrationHandlerProps {
  email: string;
  onSuccesfulRegister(): Promise<void>;
}

const Form: React.FC = () => {
  const [registrationHandler, setRegistrationHandler] =
    useState<RegistrationHandlerProps>();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const changeRegistrationHandler = (options: RegistrationHandlerProps) =>
    setRegistrationHandler(options);

  return (
    <section id="form-section" className="form-section my-5">
      <ModalOverlay show={showModal}>
        <RegisterForm
          modalOptions={registrationHandler}
          onCloseModal={closeModal}
        />
      </ModalOverlay>
      <UploadBasicForm
        onChangeRegistrationHandler={changeRegistrationHandler}
        onOpenModal={openModal}
      />
      <UploadApForm
        onChangeRegistrationHandler={changeRegistrationHandler}
        onOpenModal={openModal}
        dateUI="select"
      />
    </section>
  );
};

export default Form;
