import React from "react";
import { Form, Formik } from "formik";
import CustomInput from "./CustomInput";
import { registrationSchema } from "utils/validation-schemas";
import { RegistrationHandlerProps } from "./Form";
import { registrationHandler } from "utils/API-schemas";

interface RegisterFormProps {
  modalOptions: RegistrationHandlerProps | undefined;
  onCloseModal(): void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  modalOptions,
  onCloseModal,
}) => {
  return (
    <Formik
      validateOnChange={false} //disable the validation at every change
      validateOnBlur={false} // disable the validation at every blurs
      initialValues={{
        email: modalOptions?.email,
        first_name: "",
        last_name: "",
        accept_terms: false,
      }}
      //with Yup we can create a schema, that Formik understands
      //to each condition we can attach an error message, that the ErrorMessage component will receive automatically
      validationSchema={registrationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await registrationHandler(values);
          await modalOptions?.onSuccesfulRegister();
          setSubmitting(false);
        } catch (error: any) {
        } finally {
          onCloseModal();
        }
      }}
    >
      {({ errors, isSubmitting }) => {
        const errorMessages = Object.values(errors);

        return (
          <div className="form form-popup form-register container-lg p-1 mx-sm-auto my-sm-2 p-sm-2 p-lg-5 d-flex flex-column align-items-center justify-content-center">
            <h2 className="fw-bold">Regisztráció</h2>
            <p className="m-1 m-md-2 m-lg-3 text-center">
              A megadott email címmel még nem történt kódfeltöltés, kérjük adja
              meg a nevét és fogadja el a játékszabályzatot.
            </p>
            <ul className="list-style-type-none empty-display-none text-center bg-danger p-2 p-md-3 rounded text-white fw-bold">
              {errorMessages.map((errorMsg, index) => (
                <li key={index}>{errorMsg}</li>
              ))}
            </ul>
            <Form className="row">
              <CustomInput
                label="E-mail cím"
                type="text"
                name="email"
                readOnly
                className={`col-12 mt-2 mt-md-3 validation-${
                  errors.email ? "error" : "success"
                }`}
                labelClassName="w-100"
                inputClassName="p-2 p-lg-3 rounded-pill border border-1 w-100 fw-bold"
              />
              <CustomInput
                label="Vezetéknév"
                type="text"
                name="last_name"
                className={`col-12 col-md-6 mt-2 mt-md-3 textinput validation-${
                  errors.last_name ? "error" : "success"
                }`}
                labelClassName="w-100"
                inputClassName="p-2 p-lg-3 rounded-pill border border-1 w-100 fw-bold"
              />
              <CustomInput
                label="Keresztnév"
                type="text"
                name="first_name"
                className={`col-12 col-md-6 mt-2 mt-md-3 textinput validation-${
                  errors.first_name ? "error" : "success"
                }`}
                labelClassName="w-100"
                inputClassName="p-2 p-lg-3 rounded-pill border border-1 w-100 fw-bold text-secondary"
              />
              <CustomInput
                type="checkbox"
                label="Elfogadom a játékszabályzatot."
                name="accept_terms"
                className={`checkbox-container my-3 mt-2 mt-md-3 d-flex flex-row align-items-start col-12 validation-${
                  errors.accept_terms ? "error" : "success"
                }`}
                inputClassName="rounded-circle"
              />
              <div className="col-6">
                <button
                  className="button-secondary p-2 p-md-3 w-100 rounded-pill text-white border border-0 fw-bold text-uppercase"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Regisztráció
                </button>
              </div>
              <div className="col-6">
                <button
                  className="rounded-pill p-2 p-md-3 w-100 bg-white text-secondary border border-1 fw-bold text-uppercase col-6"
                  type="button"
                  onClick={() => {
                    onCloseModal();
                  }}
                >
                  Mégsem
                </button>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
