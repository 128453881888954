import { PeriodContext } from "context/period-context";
import { format, parseISO } from "date-fns";
import { hu } from "date-fns/locale";
import React, { useContext, useEffect, useState } from "react";
import { fetchWinners } from "utils/API-schemas";

import "./Winners.scss";

const Winners: React.FC = () => {
  const [dailyWinnersList, setDailyWinnersList] = useState<string[]>();
  const [weeklyWinner, setWeeklyWinner] = useState("");

  const promotionPeriod = useContext(PeriodContext);

  const weeks = promotionPeriod?.filter(
    (date) => date.type === "week" && parseISO(date.start_time) < new Date()
  );
  const start_time =
    (weeks && weeks[0])?.start_time || new Date().toISOString();

  const setWinnersListHandler = async (winnersArray: {
    weeklyWinner: string;
    dailyWinners: string[];
  }) => {
    const { weeklyWinner, dailyWinners } = winnersArray;
    setWeeklyWinner(weeklyWinner);
    setDailyWinnersList([...dailyWinners]);
  };

  const formatDateToApi = (date: string) =>
    format(parseISO(date), "yyyy-MM-dd");

  const formWeeksLabel = (
    weekIndex: number,
    start_time: string,
    end_time: string
  ) =>
    `${weekIndex + 1}. hét (${format(parseISO(start_time), "yyyy. MMMM d", {
      locale: hu,
    })} - ${format(parseISO(end_time), "yyyy. MMMM d", {
      locale: hu,
    })})`;

  useEffect(() => {
    (async () => {
      if (!promotionPeriod) {
        return;
      }
      try {
        const winners = await fetchWinners(formatDateToApi(start_time));
        setWinnersListHandler(winners);
      } catch (error) {}
    })();
  }, [promotionPeriod, start_time]);

  const options = weeks?.map((week: any, index: number) => {
    const { start_time, end_time } = week;
    return (
      <option key={index} value={formatDateToApi(start_time)}>
        {formWeeksLabel(index, start_time, end_time)}
      </option>
    );
  });

  return (
    <section
      id="winners-section"
      className="winners-section container-lg my-5 d-flex flex-column align-items-center justify-content-center text-center"
    >
      <div className="w-100">
        <h2 className="fontb-2">Nyertesek</h2>
        <p className="p-0 m-2 m-lg-3 fw-semibold">
          Gratulálunk a szerencséseknek!
        </p>
      </div>
      <div className="m-2 m-md-3 m-lg-4 row align-items-baseline">
        <p className="col-12 col-md-6 p-0 m-0 fw-semibold">
          Válaszd ki, melyik hét nyerteseit szeretnéd látni
        </p>
        <select
          className="styled-select col-12 col-md-6 my-3 pl-2 py-2 pl-md-3 py-md-3 rounded-pill bg-white text-secondary fw-bold border border-0"
          onChange={async (e) => {
            const winners = await fetchWinners(e.target.value);
            setWinnersListHandler(winners);
          }}
        >
          {options}
        </select>
      </div>
      <div>
        <h4 className="fw-bold mb-1 mb-md-4">A hét nyertese</h4>
        <p className="weekly border border-2 border-white m-0 p-3 p-md-4 text-white bg-primary fw-bold w-100 fs-5">
          {weeklyWinner
            ? weeklyWinner
            : "Ezen a héten még nincs heti nyertesünk"}
        </p>
        <h4 className="fw-bold mt-2 mb-1 mb-md-4 mt-md-5">Napi nyerteseink</h4>
        <div className="daily border border-2 border-white text-white bg-secondary m-0 p-3 p-md-4 p-lg-5">
          {dailyWinnersList?.length !== 0 ? (
            <ul className="daily-winners">
              {dailyWinnersList?.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          ) : (
            "Ezen a héten még nincs napi nyertesünk"
          )}
        </div>
      </div>
      <div className="m-2 m-md-3 m-lg-5 fs-7">
        <p className="p-0 m-0">A nyerteseket e-mailben értesítjük.</p>
        <p className="p-0 m-0 fw-semibold">*Feldolgozás alatt.</p>
      </div>
    </section>
  );
};

export default Winners;
