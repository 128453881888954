import React from "react";
import { Field } from "formik";

interface CustomInputProps {
  label?: string;
  name:
    | "email"
    | "code"
    | "accept_terms"
    | "day"
    | "hour"
    | "min"
    | "first_name"
    | "last_name";
  type: "text" | "checkbox" | "select" | "number" | "email" | "tel";
  placeholder?: string;
  children?: React.ReactNode;
  readOnly?: boolean;
  error?: boolean;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
  inputMode?: "none" | "text" | "decimal" | "numeric" | "tel" | "search" | "email" | "url",
  autoComplete?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  name,
  type,
  placeholder,
  children,
  readOnly,
  error,
  className,
  labelClassName,
  inputClassName,
  maxLength,
  minLength,
  min,
  max,
  inputMode,
  autoComplete,
}) => {
  // in case of checkbox we want the label after the checkbox, else before the input
  const labelLocation = type !== "checkbox" ? "before" : "after";
  // if we want a select input, the Field component must have an 'as' argument.
  // if type==='select' we set the 'as'='select', else 'as'='input'
  const selecInput = type === "select" ? "select" : "input";

  return (
    <div className={`${className} ${error ? "error" : ""}`}>
      <label className={labelClassName}>
        {labelLocation === "before" && <p>{label}</p>}
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
          as={selecInput}
          readOnly={readOnly}
          maxLength={maxLength}
          minLength={minLength}
          className={inputClassName}
          min={min}
          max={max}
          autoComplete={autoComplete}
          inputMode={inputMode}
        >
          {children}
        </Field>
        {labelLocation === "after" && <p>{label}</p>}
      </label>
    </div>
  );
};

export default CustomInput;
