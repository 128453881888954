import React from 'react';

import "./Slogan.scss";
import sloganPicture from "../../assets/slogan-picture.png";

const Hero:React.FC = () => {
  return (
    <section className="slogan-section my-5 pt-2 px-2 pt-md-3 px-md-3 pt-lg-5 px-lg-5 bg-secondary">
      <div className="p-2 p-md-3 p-lg-5 container-lg d-flex flex-column align-items-center justify-content-center text-white text-center">
        <h2 className="fontb-2">Egyéb márkaüzenet megfogalmazása 50 - 60 karakterben</h2>
        <p>
          Kiegészítő üzenet megfogalmazása, 150-200 karakterben - ennél természetesen lehet több is, de érdemesnek látjuk sablonizálni az üzenetek hosszát, hogy lényegesen ne alakítson a kialakult sablon képén.
        </p>
        <img className="slogan-picture" alt="1700 x 400 px" src={sloganPicture} />
        <a href="#unset" className="button-primary fontbu-6 m-2 m-lg-3 p-2 p-md-3 rounded-pill border border-0 text-white text-decoration-none">CTA</a>
      </div>
    </section>
  );
}

export default Hero;
