import React from "react";

import "./Description.scss";
import curvyArrow from "assets/curvy-arrow.png"

const Description: React.FC = () => {
  return (
    <section id="desc-section" className="desc-section my-5 p-lg-5 bg-secondary text-white">
      <div className="container-lg d-flex flex-column align-items-center justify-content-center text-center">
        <h2 className="m-5 fontb-2">Játék leírás</h2>
        <div className="description-visual row">
          <div className="visual-picture border border-2 border-white rounded-circle ratio ratio-1x1">
          </div>
          <div className="visual-desc">
            <h3 className="fontb-4">Vásárolj</h3>
            <p>bármilyen terméket!*</p>
          </div>
          <img className="arrow d-none d-md-block" src={curvyArrow} alt=""></img>
          <div className="visual-picture border border-2 border-white rounded-circle ratio ratio-1x1">
          </div>
          <div className="visual-desc">
            <h3 className="fontb-4">Őrizd meg</h3>
            <p>a vásárláskor kapott blokkot!</p>
          </div>
          <img className="arrow d-none d-md-block" src={curvyArrow} alt=""></img>
          <div className="visual-picture border border-2 border-white rounded-circle ratio ratio-1x1">
          </div>
          <div className="visual-desc">
            <h3 className="fontb-4">Töltsd fel</h3>
            <p>a vásárlást igazoló blokk adatait!</p>
          </div>
        </div>
        <div className="m-lg-5 text-center fs-7">
          <p className="m-0">
            * A nyereményjátékban bármilyen ... termék vásárlásával részt vehetsz.
          </p>
          <p>
            A játék időtartama alatt minden játékos összesen X darab napi
            nyereményre és 1 darab fődíjra jogosult.
          </p>
          <a className="d-block m-4 text-white" href="#unset">Részletes játékszabályzat</a>
        </div>
      </div>
    </section>
  );
};

export default Description;
