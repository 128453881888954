import React from "react";

import "./Header.scss";
import imgLogo from "assets/logo.png";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";

const Header: React.FC = () => {
  return (
    <header className="header p-1 p-xl-3 bg-secondary">
      <Navbar collapseOnSelect expand="xl" variant="dark">
        <Container>
          <Navbar.Brand className="navbar-brand text-white" href="#unset">
            <img className="logo" src={imgLogo} alt="Logó" />
          </Navbar.Brand>
          <Navbar.Toggle
            className="navbar-toggler"
            aria-controls="navbarNavDropdown"
          />
          <Navbar.Collapse
            className="collapse navbar-collapse"
            id="navbarNavDropdown"
          >
            <Nav className="navbar-nav navbar-dark">
              <Nav.Link className="nav-item" href="#form-section">
                <span className="nav-link text-white">Kódfeltöltés</span>
              </Nav.Link>
              <Nav.Link className="nav-item" href="#desc-section">
                <span className="nav-link text-white">Játék leírás</span>
              </Nav.Link>
              <Nav.Link className="nav-item" href="#unset">
                <span className="nav-link text-white">Hogyan nyerhetek?</span>
              </Nav.Link>
              <Nav.Link className="nav-item" href="#winners-section">
                <span className="nav-link text-white">Nyertesek</span>
              </Nav.Link>
              <Nav.Link className="nav-item" href="#unset">
                <span className="nav-link text-white">Játékszabályzat</span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
