import React from "react";
import { Form, Formik } from "formik";
import CustomInput from "./CustomInput";
import { uploadGeneratedCodeSchema } from "utils/validation-schemas";
import { RegistrationHandlerProps } from "./Form";
import { uploadHandlerBasic } from "utils/API-schemas";

interface UploadBasicFormProps {
  onChangeRegistrationHandler(options: RegistrationHandlerProps): void;
  onOpenModal(): void;
}

const UploadBasicForm: React.FC<UploadBasicFormProps> = ({
  onChangeRegistrationHandler,
  onOpenModal,
}) => {
  return (
    <Formik
      validateOnChange={false} //disable the validation at every change
      validateOnBlur={false} // disable the validation at every blur
      initialValues={{
        email: "",
        code: "",
      }}
      // with Yup we can create a schema, that Formik understands
      // to each condition we can attach an error message, that the ErrorMessage component will receive automatically
      validationSchema={uploadGeneratedCodeSchema}
      onSubmit={async (values, { setErrors, setSubmitting, setStatus }) => {
        const succesOperations = () => {
          setStatus({ success: "Sikeres kódfeltöltés!" });
          values.code = "";
        };

        setStatus();

        const upload = async () => {
          try {
            await uploadHandlerBasic(values);
            setSubmitting(false);
            succesOperations();
          } catch (error: any) {
            const errorMessage = error.response.data.errors[0].code;

            if (errorMessage === "email:not_found") {
              onChangeRegistrationHandler({
                email: values.email,
                onSuccesfulRegister: upload,
              });
              onOpenModal();
            }
            if (errorMessage === "code:already_uploaded") {
              setErrors({ code: "A kódot már korábban feltöltötték!" });
            }
            if (errorMessage === "code:invalid") {
              setErrors({ code: "Érvénytelen kód!" });
            }
          }
        };
        upload();
      }}
    >
      {({ errors, isSubmitting, status }) => {
        const errorMessages = Object.values(errors);

        return (
          <div className="form form-upload container-lg p-1 mx-sm-auto my-sm-2 p-sm-2 p-lg-5 d-flex flex-column align-items-center justify-content-center">
            <h2 className="fw-bold">Kódfeltöltés</h2>
            <p>Töltsd fel a csomagoláson talált kódot!</p>
            {status?.success && errorMessages.length === 0 && (
              <p className="text-center bg-success p-2 p-md-3 rounded text-white fw-bold">{status.success}</p>
            )}
            <ul className="list-style-type-none empty-display-none text-center bg-danger p-2 p-md-3 rounded text-white fw-bold">
              {errorMessages.map((errorMsg, index) => (
                <li key={index}>{errorMsg}</li>
              ))}
            </ul>
            <Form>
              <CustomInput
                label="E-mail"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="vezeteknev.keresztnev@valami.hu"
                className={`mt-2 mt-md-3 textinput validation-${
                  errors.email ? "error" : "success"
                }`}
                labelClassName="w-100"
                inputClassName="p-2 p-lg-3 rounded-pill w-100 fw-bold"
              />
              <CustomInput
                label="Kód"
                name="code"
                type="text"
                placeholder="A124234JB5Y"
                className={`mt-2 mt-md-3 textinput validation-${
                  errors.code ? "error" : "success"
                }`}
                labelClassName="w-100"
                inputClassName="p-2 p-lg-3 rounded-pill w-100 fw-bold"
              />
              <div className="my-2 my-md-4 d-flex flex-column align-items-center justify-content-center">
                <button
                  className="button-secondary w-50 p-2 p-lg-3 rounded-pill border border-0 text-white text-uppercase fw-bold"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Feltöltöm a kódot
                </button>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default UploadBasicForm;
