import * as Yup from "yup";

const REGEX_EMAIL = /^[\w.%+-]+@[\w.-]+\.[a-z]{2,}$/i;
const REGEX_CODE_AP = /^[0-9A-SU-Z]\d{8}$/i;
const REGEX_CODE_GENERATED = /^[0-9A-Z]{8}$/i;

const uploadCommonSchema = Yup.object({
  email: Yup.string()
    .required("Az e-mail kitöltése kötelező!")
    .matches(REGEX_EMAIL, "Helytelen e-mail cím!"),
});

export const uploadAPCodeSchema = Yup.object({
  code: Yup.string()
    .required("A kód kitöltése kötelező!")
    .matches(REGEX_CODE_AP, "Helytelen kód!"),
  hour: Yup.number()
    .required("Az óra kitöltése kötelező!")
    .min(0, "Az óra értékét 0-23 adja meg!")
    .max(23, "Az óra értékét 0-23 adja meg!")
    .integer("Érvénytelen óra!"),
  min: Yup.number()
    .required("A perc kitöltése kötelező!")
    .min(0, "A perc értékét 0-59 adja meg!")
    .max(59, "A perc értékét 0-59 adja meg!")
    .integer("Érvénytelen perc!"),
}).concat(uploadCommonSchema);

export const uploadGeneratedCodeSchema = Yup.object({
  code: Yup.string()
    .required("A kód kitöltése kötelező!")
    .matches(REGEX_CODE_GENERATED, "Helytelen kód!"),
}).concat(uploadCommonSchema);

export const registrationSchema = Yup.object({
  first_name: Yup.string().required("A keresztnév kitöltése kötelező!"),
  last_name: Yup.string().required("A vezetéknév kitöltése kötelező!"),
  accept_terms: Yup.boolean().oneOf(
    [true],
    "A játékszabályzat elfogadása kötelező!"
  ),
});
