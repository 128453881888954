import React, { createContext, useEffect, useState } from "react";
import { fetchPeriods } from "utils/API-schemas";

export type Weeks = { start_time: string; end_time: string; type: string }[];

export const PeriodContext = createContext<Weeks | null>(null);

export const PeriodContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [promotionPeriod, setPromotionPeriod] = useState<Weeks | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const periodResponse = await fetchPeriods();
        setPromotionPeriod(periodResponse.data);
      } catch (error) {}
    })();
  }, []);

  return (
    <PeriodContext.Provider value={promotionPeriod}>
      {children}
    </PeriodContext.Provider>
  );
};
