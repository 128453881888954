import axios from "axios";

export const uploadHandlerBasic = async (config: {
  email: string;
  code: string;
}) => {
  const response = await axios.post(
    "https://ncp-backend.staging.moonproject.io/api/ncp/code/upload",
    {
      ...config,
    }
  );

  return response;
};

export const uploadHandlerAp = async (config: {
  email: string;
  code: string;
  purchase_time: string;
}) => {
  const response = await axios.post(
    "https://ncp-backend.staging.moonproject.io/api/ncp/code/upload-ap",
    {
      ...config,
    }
  );

  return response;
};

export const registrationHandler = async (config: {
  email: string | undefined;
  first_name: string;
  last_name: string;
  accept_terms: boolean;
}) => {
  const response = await axios.post(
    "https://ncp-backend.staging.moonproject.io/api/ncp/user/register",
    {
      ...config,
    }
  );
  return response;
};

interface Winner {
  id: number;
  type: string;
  start_time: string;
  end_time: string;
  name: string;
  approved: boolean;
}

type Winners = Partial<Record<string, Winner[]>>;

export const fetchWinners = async (date: string) => {
  const start_time = date;

  const response = await axios.get<Winners>(
    `https://ncp-backend.staging.moonproject.io/api/ncp/winners?type=week,day&date=${start_time}`
  );
  const dailyWinners = response.data.day
    ? response.data.day.map((winnerData) => winnerData.name)
    : [];
  const weeklyWinner = response.data.week ? response.data.week[0].name : "";

  return { weeklyWinner, dailyWinners };
};

export const fetchPeriods = async () => {
  const response = await axios.get(
    "https://ncp-backend.staging.moonproject.io/api/ncp/periods"
  );
  return response;
};
